import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Show = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loding, setLoading] = useState(true);
  const navigate=useNavigate()
  const comeBackUsers=()=>{
    navigate("/users")
  }
  const fetchUser = async () => {
    try {
      const data = await fetch("https://jsonplaceholder.typicode.com/users");
      const res = await data.json();
      const userItem = res.find((elem) => elem.username === id);
      setUser(userItem);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(true);
    }
  };
  useEffect(() => {
    fetchUser();
  }, []);
  return (
    user && (
      <div className="card m-5" style={{ width: "18rem" }}>
        <div className="card-header bg-dark text-white">{user.name}</div>
        <ul className="list-group list-group-flush bg-dark text-white">
          <li className="list-group-item bg-dark text-white">
            {user.username}
          </li>
          <li className="list-group-item bg-dark text-white">{user.phone}</li>
          <li className="list-group-item bg-dark text-white">{user.email}</li>
          <li className="list-group-item bg-dark text-white"><button className=" btn btn-success btn-sm text-capitalize mx-1" onClick={comeBackUsers}>come back</button></li>
        </ul>
      </div>
    )
  );
};

export default Show;
