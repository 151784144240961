import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Update from "./Update";

function Edit() {
  const { id } = useParams();
  const [post,setPost]=useState(null)
  const fetchPost=async()=>{
    try {
        const res= await axios.get(`https://jsonplaceholder.typicode.com/posts/${id}`)
        setPost(res.data)
    } catch (error) {
        console.log(error.message);
    }
  }
  useEffect(()=>{
    fetchPost()
  },[])
  return (
    <div className="col-6 offset-3 mt-2 bg-dark text-white p-5">
        {post && <Update post={post}/>}
    </div>
  );
}

export default Edit;
