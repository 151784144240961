import axios from "axios";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function Create() {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const titleRef = useRef("");
  const bodyRef = useRef("");
  const navigate=useNavigate()
  const eventHandler = (e) => {
    e.preventDefault();
    const create = async () => {
      try {
        let res = await axios.post(
          "https://jsonplaceholder.typicode.com/posts",
          {
            title: title,
            body: body,
          },
          {
            headers: {
              "Content-Type": "application/json ; charset=UTF-8",
            },
          }
        );
        console.log(res.data);
      } catch (error) {
        console.log(error.message);
      }
    };

    create();
    titleRef.current.value = "";
    bodyRef.current.value = "";
    Swal.fire({
      title: "Good job!",
      text: "You clicked the button!",
      icon: "success",
    });
    navigate("/posts")
  };
  return (
    <div className="col-6 offset-3 mt-2 bg-dark text-white p-5">
      <form action="#" method="post" onSubmit={(e) => eventHandler(e)}>
        <div className="d-block">
          <label htmlFor="title" className="mb-2">
            title
          </label>
          <input
            type="text"
            className="form-control mb-2"
            placeholder="title"
            onChange={(e) => setTitle(e.target.value)}
            ref={titleRef}
          />
          {title.length === 0 ? (
            <p className="text-danger">this field required...</p>
          ) : (
            ""
          )}
          {title.length >= 1 && title.length < 5 ? (
            <p className="text-danger">this filed more than 5 cahcrecter</p>
          ) : (
            ""
          )}
          {title.length > 500 ? (
            <p className="text-danget">
              this field dont more than 500 charecter 500
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="d-block">
          <label htmlFor="body" className="mb-2">
            body
          </label>
          <textarea
            className="form-control mb-2"
            placeholder="body"
            style={{ height: "150px", resize: "none" }}
            onChange={(e) => setBody(e.target.value)}
            ref={bodyRef}
          ></textarea>
          {body.length === 0 ? (
            <p className="text-danger">this field required...</p>
          ) : (
            ""
          )}
          {body.length >= 1 && body.length < 5 ? (
            <p className="text-danger">this filed more than 5 cahcrecter</p>
          ) : (
            ""
          )}
          {body.length > 500 ? (
            <p className="text-danget">
              this field dont more than 500 charecter 500
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="d-block">
          <button
            type="submit"
            value="create"
            className="btn btn-success btn-sm text-capitalize"
            disabled={title.length > 5 && body.length > 5 ? null : "disabled"}
          >
            create
          </button>
        </div>
      </form>
    </div>
  );
}

export default Create;
