import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <nav className="navbar navbar-expand-sm bg-dark navbar-dark">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsibleNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                className={(navDate) =>
                  navDate.isActive
                    ? "nav-link text-capitalize bg-success text-white"
                    : "nav-link text-capitalize "
                }
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={(navDate) =>
                  navDate.isActive
                    ? "nav-link text-capitalize bg-success text-white"
                    : "nav-link text-capitalize "
                }
                to="/users"
              >
                users
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={(navDate) =>
                  navDate.isActive
                    ? "nav-link text-capitalize bg-success text-white"
                    : "nav-link text-capitalize "
                }
                to="/posts"
              >
                posts
              </NavLink>
            </li>
            <li>
              {" "}
              <NavLink
                className={(navDate) =>
                  navDate.isActive
                    ? "nav-link text-capitalize bg-success text-white"
                    : "nav-link text-capitalize "
                }
                to="/images"
              >
                images
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
