import { Link } from "react-router-dom";

const Post = ({ post }) => {
  return (
    <div className="card" style={{ width: "18rem" }}>
      <div className="card-header bg-dark text-white">
        <Link to={`/posts/${post.id}`}>{post.title.substr(0, 20)}</Link>
      </div>
      <ul className="list-group bg-dark text-white list-group-flush">
        <li className="list-group-item bg-dark text-white">
          {post.title.substr(0, 20)}
        </li>
        <li className="list-group-item bg-dark text-white">
          {post.body.substr(0, 50)}
        </li>
      </ul>
    </div>
  );
};

export default Post;
