import { Route, Routes } from "react-router-dom";
import Posts from "./Posts";
import Create from "./Create";
import Show from "./Show";
import Edit from "./Edit";

const RouterPosts = () => {
  return (
    <Routes>
      <Route path="/" element={<Posts />} />
      <Route path="/:id" element={<Show />} />
      <Route path="/create" element={<Create />} />
      <Route path="/edit/:id" element={<Edit />} />
    </Routes>
  );
};

export default RouterPosts;
