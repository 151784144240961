import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

function Show() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const fetchPost = async () => {
    try {
      const data = await fetch(
        `https://jsonplaceholder.typicode.com/posts/${id}`
      );
      const res = await data.json();
      setPost(res);
    } catch (error) {
      console.log(error.message);
    }
  };
  const comebackPost = () => {
    navigate("/posts");
  };
  const deletePost = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success mx-1",
        cancelButton: "btn btn-danger mx-1",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success",
          });
          const res=axios.delete(`https://jsonplaceholder.typicode.com/posts/${id}`,{method:"delete"})
          console.log(res.data);
          navigate("/posts")
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Your imaginary file is safe :)",
            icon: "error",
          });
        }
      });
  };
  const editPost=()=>{
    navigate(`/posts/edit/${id}`)
  }
  useEffect(() => {
    fetchPost();
  }, []);
  return (
    post && (
      <div className="card m-5" style={{ width: "18rem" }}>
        <div className="card-header bg-dark text-white">
          {post.title.substr(0, 20)}
        </div>
        <ul className="list-group bg-dark text-white list-group-flush">
          <li className="list-group-item bg-dark text-white">
            {post.title.substr(0, 20)}
          </li>
          <li className="list-group-item bg-dark text-white">
            {post.body.substr(0, 50)}
          </li>

          <li className="list-group-item bg-dark text-white">
            <button
              onClick={comebackPost}
              className="btn btn-success btn-sum mx-1"
            >
              comeback
            </button>

            <button
              onClick={deletePost}
              className="btn btn-danger btn-sum mx-1"
            >
              delete
            </button>
            <button
              onClick={editPost}
              className="btn btn-warning btn-sum mx-1"
            >
              edit 
            </button>
          </li>
        </ul>
      </div>
    )
  );
}

export default Show;
