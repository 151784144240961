import { Route, Routes } from "react-router-dom";
import Users from "./Users";
import Show from "./Show";

const RouterUsers = () => {
  return (
    <Routes>
      <Route path="/" element={<Users />} />
      <Route path="/:id" element={<Show />} />
    </Routes>
  );
};

export default RouterUsers;
