import { useEffect, useState } from "react";
import Post from "./Post";
import { Link } from "react-router-dom";

const Posts = () => {
  const [posts, setPosts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const fetchPosts = async () => {
    try {
      const data = await fetch("https://jsonplaceholder.typicode.com/posts");
      const res = await data.json();
      setPosts(res);
      setLoading(false);
      setError("");
    } catch (error) {
      console.log(error.message);
      setError(error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPosts();
  }, []);
  return (
    <div>
      {loading && (
        <div className="text-center">
          <span className="spinner-border spinner-border-sm"></span>
        </div>
      )}
      <div className="text-center p-5">
        <Link className="btn btn-primary btn-sm" to="/posts/create">
          create
        </Link>
      </div>
      <div className="d-flex justify-content-center gap-2 flex-wrap p-5">
        {
          posts?.map((elem) => {
            return <Post post={elem} key={elem.id} />;
          })}
      </div>
    </div>
  );
};

export default Posts;
