const Home = () => {
    return ( 
        <div className="col-6 offset-3 mt-3 bg-dark p-5 text-white">
            <h1 className="text-capitalize">this page is home</h1>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis eos sequi eius expedita vero. Quibusdam minima maxime, alias nam omnis corrupti obcaecati exercitationem a, consequuntur facilis autem vel accusantium est, quaerat recusandae? Sunt necessitatibus delectus architecto aut! Laboriosam ipsam facere et suscipit, magni ipsum numquam vel labore unde itaque hic debitis consequuntur quam autem veniam a pariatur natus fuga dolorem tempora eius nam, at sint cum. Est aperiam soluta cumque velit ducimus, a inventore itaque praesentium quidem debitis veniam dolor deserunt illo dicta recusandae, ullam corporis ipsum nemo labore ratione sequi, iusto voluptas ab culpa! Natus autem omnis cupiditate ut.
            </p>
            <a href="#" className="btn btn-success btn-sm text-capitalize">read-more</a>
        </div>
     );
}
 
export default Home;