import { useEffect, useState } from "react";
import User from "./User";

const Users = () => {
    const [users,setUsers]=useState(null)
    const [loading,setLoading]=useState(true)
    const [error,setError]=useState("")
    const fetchUsers=async()=>{
        try {
            const data=await fetch("https://jsonplaceholder.typicode.com/users")
            const res=await data.json()
            setUsers(res)
            setError("")
            setLoading(false)
        } catch (error) {
            console.log(error.message);
            setError(error.message)
            setLoading(true)
        }
    }
    useEffect(()=>{
        fetchUsers()
    },[])
    return ( 
        <div>
            {loading && <div className="text-center"><span className="spinner-border spinner-border-sm"></span></div>}
            <div className="d-flex justify-content-center gap-2 flex-wrap p-5">
            {users && users.map((elem)=>{
                return <User user={elem} key={elem.id}/>
            })}
            </div>
        </div>
     );
}
 
export default Users;